<template>
  <section>
    <div class="d-flex align-center mb-6">
      <div class="text-h5 font-weight-bold mr-4">
        <span class="saira">Tabela de classificação</span>
      </div>
    </div>

    <iframe
      v-if="!!_url"
      :src="_url"
      width="100%"
      height="500"
      frameborder="0"
      scrolling="auto"
      allowtransparency="true"
    />

    <div v-else class="d-flex justify-center align-center">
      <span class="text-body-1 subtext--text">
        Tabela disponível em breve!
      </span>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["selected"]),

    _url() {
      return this.selected.chalongeUrl;
    },
  },
};
</script>

<style></style>
